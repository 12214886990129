<template>
  <ws-dialog
      v-model="display"
      :title="$t('AddChat')"
      @save="addChat"
      :save-text="$t('Create')"
      width="700"
  >
    <v-row>
      <v-col cols="12" md="7">
        <ws-text-field
            v-model="entityData.name"
            :label="$t('ChatName')"
            :placeholder="$t('EnterChatName')"
            :error-messages="nameError"
            :error="!!nameError"
            @input="nameError = null"
            :hide-details="false"
        />
      </v-col>
      <v-col cols="12" md="5">
        <ws-select
            style="margin-top: -5px"
            v-model="entityData.type"
            :items="chatTypesSelect"
            :label="$t('ChatTypeLabel')"
            :tooltip="$t('AutoChatTooltip')"
        />
      </v-col>
    </v-row>



    <v-expand-transition mode="out-in">
      <div key="dynamic" v-if="entityData.type === 'dynamic_chat'">
        <div class="d-flex justify-space-between align-center ">
          <h4>{{ $t('Managers') }}</h4>
          <ws-switch  v-model="entityData.all_managers" :placeholder="$t('All')" />
        </div>
        <v-expand-transition>
          <div v-if="!entityData.all_managers">
            <ws-data-table
                v-if="managersSelect.length > 0"
                :items="managersSelect"
                :headers="contactsHeaders"
                :row-action="selectManager"
            >

              <template #item.name="{item}">
                <div class="d-flex align-center">
                  <v-avatar size="40" :color="wsLIGHTACCENT" class="mr-3">
                    <v-img v-if="item.img" :src="item.img"></v-img>
                    <v-icon v-else  dark>mdi-account</v-icon>
                  </v-avatar>
                  <div>
                    <h5 >{{ $t(item.name) }}</h5>
                  </div>
                </div>
              </template>
              <template #item.selected="{item}">
                <div class="d-flex justify-end">
                  <ws-check-box v-model="item.selected" :small="false" />
                </div>
              </template>
            </ws-data-table>
          </div>
        </v-expand-transition>

        <h4 class="mb-3">{{ $t('Students') }}</h4>
        <h5 class="mb-5" :style="`color : ${wsDARKLIGHT}`"> {{ $t('DynamicChatDescription') }}</h5>
        <v-row >
          <v-col cols="12" md="6">
            <ws-select
                v-model="entityData.course_id"
                :items="coursesSelect"
                :label="$t('Course')"
                :error-messages="courseError"
                :error="!!courseError"
                @input="courseError = null"
                :hide-details="!courseError"
            />

          </v-col>
          <v-col  v-if="selectedCourse.payed && selectedCourse.has_payment_plans"
                  cols="12" md="6">
            <ws-select
                v-model="entityData.payment_plan_id"
                :items="selectedCourse.payment_plans_select || [] "
                :label="$t('PaymentPlan')"
                clearable
            />
          </v-col>
          <v-col v-if="selectedCourse.online && selectedCourse.scheduled"
                 cols="12" md="6">
            <ws-select
                v-model="entityData.enrollment_wave_id"
                :items="selectedCourse.enrollemnt_waves_select || [] "
                :label="$t('EnrollmentWave')"
                clearable
            />
          </v-col>
          <v-col v-if="selectedCourse.has_groups"
                 cols="12" md="6"
          >
            <ws-select
                v-model="entityData.course_group_id"
                :items="selectedCourse.groups_select || [] "
                :label="$t('Group')"
                clearable
            />
          </v-col>

        </v-row>

      </div>
      <div key="manual"  v-if="entityData.type === 'chat'">
        <div class="d-flex justify-space-between align-center py-5 " >

          <h4 :style="`color : ${!participantsError ? wsACCENT : wsWARNING}`">
            {{ selectedContacts.length === 0 ? $t('ChooseChatContacts') : $t('Chosen') }}
            <span v-if="selectedContacts.length > 0">: {{ $tc('users' , selectedContacts.length) }} </span>
          </h4>
          <v-btn
              small
              v-if="selectedContacts.length > 0"
              @click="deselectAll"
              icon
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <ws-data-table
            v-if="contacts.length > 0"
            :items="contacts"
            :headers="contactsHeaders"
            :row-action="selectContact"
        >

          <template #item.name="{item}">
            <div class="d-flex align-center">
              <v-avatar size="40" :color="wsLIGHTACCENT" class="mr-3">
                <v-img v-if="item.img" :src="item.img"></v-img>
                <v-icon v-else  dark>mdi-account</v-icon>
              </v-avatar>
              <div>
                <h5 >{{ $t(item.name) }}</h5>
                <h5 class="font-weight-light">{{ $t(item.type) }}</h5>
              </div>
            </div>
          </template>
          <template #item.selected="{item}">
            <div class="d-flex justify-end">
              <ws-check-box v-model="item.selected" :small="false" />
            </div>
          </template>

        </ws-data-table>
      </div>
    </v-expand-transition>

  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newChatDialog",
  props : {
    value : {
      type : Boolean,
    }
  },
  data() {
    return {
      display : false,
      coursesSelect : [],
      entityData : {
        type : 'dynamic_chat'
      },
      contacts : [],
      groups : [],
      selectedGroup : "",
      nameError : null,
      courseError : null,
      participantsError : false,
      managersError : false,
      managersSelect : []
    }
  },
  computed : {
    selectedContacts() {
      if ( this.contacts.length === 0 ) {
        return []
      }
      return this.contacts.filter( el => el.selected)
    },
    selectedManagers() {
      if ( this.managersSelect.length === 0 ) {
        return []
      }
      return this.managersSelect.filter( el => el.selected)
    },
    managersUuids() {
      if ( this.selectedManagers.length === 0 ) {
        return []
      }
      return this.selectedManagers.map(el => el.id)
    },
    contactsUuids() {
      if ( this.selectedContacts.length === 0 ) {
        return []
      }
      return this.selectedContacts.map(el => el.uuid )
    },
    contactsHeaders() {
      return [
        { text  : this.$t('HumanName') , value : 'name' },
        { text  : this.$t('Selected') ,value : 'selected' , width : '10px' }
      ]
    },
    selectedCourse() {
      if ( this.coursesSelect.length === 0 ) {
        return {}
      }
      let course = this.coursesSelect.find(el => el.value === this.entityData.course_id)
      if (!course ) {
        return {}
      }
      return course
    },
    chatTypesSelect() {
      return [
        { text : this.$t('ManualChat') , value : 'chat' },
        { text : this.$t('AutoChat') , value : 'dynamic_chat' },
      ]
    },
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  methods : {
    ...mapActions('courses',   [ 'GET_COURSES_SELECT' ]),
    ...mapActions('messenger', [ 'ADD_CHAT' , 'GET_CONTACT_BOOK' ]),
    ...mapActions( 'businessUsers', [ 'GET_MANAGERS_SELECT' ] ),

    async addChat() {
      if (!this.entityData.name) {
        this.nameError = this.$t('EnterName')
        this.notify(this.$t('EnterName') , 'warning')
        return
      }
      if (this.entityData.type === 'dynamic_chat' && !this.entityData.course_id) {
        this.courseError = this.$t('ChooseCourse')
        this.notify(this.$t('ChooseCourse') , 'warning')
        return
      }

      if (this.entityData.type === 'chat' && this.selectedContacts.length === 0) {
        this.participantsError = true
        this.notify(this.$t('ChooseChatContacts') , 'warning')
        return
      }

      if (this.entityData.type === 'chat' ) {
        this.entityData.users = this.contactsUuids
      }

      if (this.entityData.type === 'dynamic_chat' && !this.entityData.all_managers ) {
        this.entityData.managers = this.managersUuids
      }

      let result = await this.ADD_CHAT(this.entityData)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.$store.state.messenger.activeChats.unshift(result)
      this.$emit('input' , false)
      this.notify(this.$t('ChatCreated') , 'success')
    },

    deselectAll() {
      this.contacts.forEach((_,index) => {
        this.contacts[index].selected = false
      })
    },

    selectManager(item) {
      let index = this.managersSelect.findIndex( el=> el.id === item.id )
      if ( index === -1 ) {
        return
      }
      if ( !item.select ) {
        this.managersError = false
      }
      this.managersSelect[index].selected = !item.selected
      this.managersSelect = this.COPY(this.managersSelect)
    },

    selectContact(item) {
      let index = this.contacts.findIndex( el=> el.uuid === item.uuid )
      if ( index === -1 ) {
        return
      }
      if ( !item.select ) {
        this.participantsError = false
      }
      this.contacts[index].selected = !item.selected
      this.contacts = this.COPY(this.contacts)
    },

    async getManagersSelect() {

      let result = await this.GET_MANAGERS_SELECT()
      if ( !result || result === true ) {
        return
      }
      result.forEach((manager) => {
        manager.selected = false
      })
      this.managersSelect = this.COPY(result)

    },
    async getContactsSelect() {
      let result = await this.GET_CONTACT_BOOK()
      if (!result || result === true ) {
        return
      }
      result.contacts.forEach((contact) => {
        contact.selected = false
      })
      this.contacts = this.COPY(result.contacts)
    },
    async getCoursesSelect() {
      let result = await this.GET_COURSES_SELECT();
      if ( !result || result === true ) {
        return
      }
      this.coursesSelect = result
    },
    initPage() {

      this.display = this.value

      this.getCoursesSelect()
      this.getManagersSelect()
      this.getContactsSelect()
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>